<template>
  <el-dialog :title="!dataForm.id ? '新增' : '修改'" :close-on-click-modal="false" width="800px" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="80px">
      <el-form-item label="班次名字" prop="name">
        <el-input v-model="dataForm.name" style="width: 240px" @change="nameChange()" placeholder="班次名字"></el-input>
      </el-form-item>
      <el-form-item label="班次简称" prop="abbreviationName">
        <el-input v-model="dataForm.abbreviationName" maxlength="1" style="width: 240px" placeholder="班次简称"></el-input>
      </el-form-item>
      <el-form-item label="时间范围" required>
        <div style="display: flex;">

          <!-- 是否跨天        -->
          <el-select v-model="dataForm.startTimeIsCrossDay" style="width: 60px;margin-right: 10px;">
            <el-option v-for="item in $store.state.dict.isCrossDay" :key="item.key" :label="item.value"
              :value="item.key"></el-option>
          </el-select>

          <el-form-item prop="startTime">
            <el-time-picker :clearable="false" v-model="dataForm.startTime" value-format="HH:mm" format="HH:mm"
              placeholder="选择时间范围">
            </el-time-picker>
          </el-form-item>
          <div style="padding: 0 10px"> - </div>
          <!-- 是否跨天        -->
          <el-select v-model="dataForm.endTimeIsCrossDay" style="width: 60px;margin-right: 10px;">
            <el-option v-for="item in $store.state.dict.isCrossDay" :key="item.key" :label="item.value"
              :value="item.key"></el-option>
          </el-select>
          <el-form-item prop="endTime">
            <el-time-picker :clearable="false" v-model="dataForm.endTime" value-format="HH:mm" format="HH:mm"
              placeholder="选择时间范围">
            </el-time-picker>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="" prop="">
        {{ timeValue }}
      </el-form-item>
      <el-form-item label="颜色" prop="color">
        <el-color-picker v-model="dataForm.color" size="small" style="margin-top:4px;"></el-color-picker>
      </el-form-item>

      <el-form-item label="业务类型" prop="businessType">
        <el-radio-group v-model="dataForm.businessType">
          <el-radio v-for="(typ, i) in typebusiness" :key="i" :label="typ.key" style="margin-top:14px;">{{ typ.value }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="类型" prop="shiftType">
        <el-radio-group v-model="dataForm.shiftType">
          <el-radio v-for="(typ, i) in $store.state.dict.schedulingPeriodType" :key="i" :label="typ.key" style="margin-top:14px;">{{ typ.value }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-radio-group v-model="dataForm.state" style="margin-top:14px;">
          <el-radio label="1">启用</el-radio>
          <el-radio label="0">停用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { _debounce } from '@/utils/utils.js'
import {
  querySchedulingPeriodById,
  saveSchedulingPeriod,
  updateSchedulingPeriod
} from "../../api/schedulingperiod/schedulingperiod";

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        name: '',
        abbreviationName: '',
        startTime: '',
        startTimeIsCrossDay: '0',
        endTime: '',
        endTimeIsCrossDay: '0',
        color: '#f56c6c',
        businessType: '',
        shiftType: '',
        state: '1'
      },
      dataRule: {
        name: [
          { required: true, message: '班次名字不能为空', trigger: 'blur' },
          { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
        ],
        abbreviationName: [
          { required: true, message: '班次简称不能为空', trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: '开始时间不能为空', trigger: 'blur' }
        ],
        startTimeIsCrossDay: [
          { required: true, message: '开始时间是否跨天不能为空', trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '结束时间不能为空', trigger: 'blur' }
        ],
        endTimeIsCrossDay: [
          { required: true, message: '结束时间是否跨天不能为空', trigger: 'blur' }
        ]
      },
      typebusiness: [
        {
          key: '1',
          value: '居家隔离'
        },
        {
          key: '2',
          value: '集中隔离'
        }, {
          key: '3',
          value: '转运调度'
        }
      ]
    }
  },
  mounted() {
    console.log(this.$store.state.app.hospitalId)
  },
  computed: {
    timeValue() {
      let startList = this.dataForm.startTime.split(':');
      let endList = this.dataForm.endTime.split(':');
      if (startList.length < 2 || endList.length < 2) return '';
      let m = endList[1] - startList[1];
      let h = endList[0] - startList[0];
      h += (this.dataForm.endTimeIsCrossDay - this.dataForm.startTimeIsCrossDay) * 24;
      if (h < 0) return '错误时间范围'
      if (m < 0) {
        if ((h - 1) < 0) return '错误时间范围'
        return `共${h - 1}小时${60 + m}分`
      }
      return `共${h}小时${m}分`
    }
  },
  methods: {
    init(id) {
      this.dataForm.id = id || ''
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          querySchedulingPeriodById(this.dataForm.id).then(res => {
            this.dataForm = res.data.data
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit:_debounce(function() {
      console.log(123);
      this.$refs['dataForm'].validate((valid) => {
        if (valid && this.timeRange()) {
          this.dataForm.hospitalId = this.$store.state.app.hospitalId
          if (this.dataForm.id) {
            updateSchedulingPeriod(this.dataForm).then(({ data }) => {
              if (data && data.code === '200') {
                this.$message({
                  message: '操作成功',
                  type: 'success'
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message({
                  message: data.msg || '操作失败',
                  type: 'error'
                })
              }
            })
          } else {
            saveSchedulingPeriod(this.dataForm).then(({ data }) => {
              if (data && data.code === '200') {
                this.$message({
                  message: '操作成功',
                  type: 'success'
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message({
                  message: data.msg || '操作失败',
                  type: 'error'
                })
              }
            })
          }
        }
      })
    },300),
    // 名字改变
    nameChange() {
      this.dataForm.abbreviationName = this.dataForm.name.substr(0, 1)
    },
    // 判断时间范围
    timeRange() {
      if (this.dataForm.startTimeIsCrossDay === this.dataForm.endTimeIsCrossDay) {
        if (this.dataForm.startTime > this.dataForm.endTime) {
          this.$message({
            message: '开始时间不能大于结束时间',
            type: 'error'
          })
          return false
        }
        return true
      } else if (this.dataForm.startTimeIsCrossDay === '1' && this.dataForm.endTimeIsCrossDay === '0') {
        this.$message({
          message: '开始时间不能大于结束时间',
          type: 'error'
        })
        return false
      } else {
        return true
      }
    }
  }
}
</script>
